<template>
    <div class="card">
        <div class="card-inner">
            <div class="card-title">
                    <a-select class="float-right" @change="getHolidays()" v-model="startYear" default-value="2022" style="width: 160px;" >
                       <a-select-option value="2022">
                           This Year
                       </a-select-option>
                       <a-select-option value="2021">
                           Last Year
                       </a-select-option>
                    </a-select>  
                <!-- <button v-on:click="requestHoliday()" class="btn btn-dark btn-sm float-right">Request A Holiday</button> -->
                <h5>Holiday Summaries</h5>
            </div>
            <div class="card-body">
                <vue-good-table v-if="holidaySummary"
                  :columns="columns"
                  :rows="holidaySummary"
                  @on-cell-click="showProfile"
                  styleClass="vgt-table condensed tblhov"
                  :search-options="{ enabled: true, placeholder: 'Search Invoices'}"
                                      :sort-options="{ enabled: true,}"
                  :pagination-options="{
                                  enabled: true,
                                  mode: 'records',
                                  perPage: 50,
                                  position: 'bottom',
                                  perPageDropdown: [50,100,150],
                                  dropdownAllowAll: false,
                                  setCurrentPage: 1,
                                  nextLabel: 'Next',
                                  prevLabel: 'Prev',
                                  rowsPerPageLabel: 'Rows per page',
                                  ofLabel: 'of',
                                  pageLabel: 'page', // for 'pages' mode
                                  allLabel: 'All',
                              }"
                >
                </vue-good-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
        startYear: '2022',
        holidaySummary: [],
        placement: 'bottomCenter',
        columns: [
          {
            label: 'Name',
            field: 'userName',
          },
          {
            label: 'Total Entitlement',
            field: 'entitlement'
          },
          { 
            label: 'Holiday Taken',
            field: 'holidaysTaken', 
          },
          { 
            label: 'Bank Holiday Taken',
            field: 'bankHolidaysTaken', 
          },
          { 
            label: 'Holiday Scheduled',
            field: 'holidaysScheduled', 
          },
          { 
            label: 'Holiday Pending Approval',
            field: 'holidaysUnapproved', 
          },
          { 
            label: 'Bank Holidays Remaining',
            field: 'bankHolidaysRemaining', 
          },
          { 
            label: 'Total Sick Days',
            field: 'totalSickDays', 
          },
          { 
            label: 'Remaining',
            field: 'remainingHoliday', 
          },
          { 
            label: 'Total Accrued',
            field: 'totalAccruedDays', 
          },
          { 
            label: 'Total Left',
            field: 'totalRemaining', 
          },
        ],
        showHoliday: false,
        selectedHoliday: null,
    }
  },
  created() {
    this.getView()
      this.getHolidays()
  },
  methods: {
    getView() {
        this.$http.get('/ViewAccess/holidayrequests')
        .then(() => {
        })
        .catch(() => {
        })
    },
    holidayUpdated () {
      this.getHolidays();
      this.showHoliday = false
      this.selectedHoliday = null
    },
    getHolidays(){
       let searchFilters  =  {startYear: this.startYear}
      this.$http.post('/people/Get_HolidaySummaries/', searchFilters)
      .then((response) => {
          this.holidaySummary = response.data
      })
      .catch(() => {
      this.$message.error('There has been an error')
      })
    },    
    showProfile(params) {
      //alert(JSON.stringify(params))
      this.$router.push({name: 'employee', query: {userId: params.row.userId}})
    },
  },
}
</script>

<style>
.card-body {
    padding: 5px;
    padding-left: 0px;
}
</style>